<template>
  <div class="chart-wrap" :class="{ 'is-loading': isLoading, 'chart-wrap-minified': minified }">
    <apexchart
        ref="chart"
        type="line"
        :options="options"
        :series="series"
        width="100%"
        height="100%"
    ></apexchart>
<!--    <EChartViewComponent></EChartViewComponent>-->
    <!-- <div v-if="!code || !series.length">
      <p class="mt-3">Nejsou k dispozici žádná data.</p>
    </div> -->
    <div class="chart-wrap-overlay chart-wrap-overlay--loading">
      <div class="chart-wrap-overlay-content">
        <div class="spinner-grow" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <p class="mt-3">Načítám data</p>
      </div>
    </div>
  </div>
</template>

<script>
import http from '@/http';

export default {
  props: ['code', 'embedded', 'dataChoice', 'from', 'to', 'initialDeposit', 'regularDeposit', 'alternativeEvaluation', 'minified', 'range', 'rangeInYears'],

  data() {
    return {
      valueType: 'percentage',
      options: {
        chart: {
          id: 'items-chart',
          width: '100%',
          toolbar: {
            show: true,
            tools: {
              download: true,
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false,
              customIcons: []
            }
          }
          /* type: 'area' */
        },
        xaxis: {
          categories: []
        },
        yaxis: {
          decimalsInFloat: 2
        },
        stroke: {
          curve: 'smooth'
        },
        colors: ['#004148', '#d18e6a', '#697d80']
      },
      series: [],
      codeForChart: this.code,
      isLoading: true,
      data: {chart_data: {}}
    };
  },

  mounted() {
    //this.getChartData(this.code);
    let self = this;
    if (self.embedded) {
      self.options.chart.toolbar.show = false;
    }

    if (self.minified) {
      // self.options.responsive = false;
      self.options.chart.toolbar.show = false;
      self.options.grid = {
        show: true
      };
      self.options.legend = {
        fontSize: '9px',
        horizontalAlign: 'left',
        markers: {
          width: '9px',
          height: '9px',
          radius: '9px'
        }
      };
    } else {
      self.options.responsive = [
        {
          breakpoint: 767,
          options: {
            xaxis: {
              tickAmount: 5
            }
          }
        },
        {
          breakpoint: 1200,
          options: {
            xaxis: {
              tickAmount: 12
            }
          }
        }
      ];
    }
  },

  /*   computed: { */
  /* range() {
    // `this` points to the component instance
    let min = this.data['data_spec']['min-value'];
    let max = this.data['data_spec']['max-value'];
    let absDiff = Math.abs(max - min);
    let step = 1;

    if (absDiff <= 5) {
      step = 1;
    } else if (absDiff <= 10) {
      step = 2;
    } else if (absDiff <= 20) {
      step = 4;
    } else step = 5;

    this.$refs.chart.updateOptions({
      yaxis: {
        tickAmount: step,
      },
    });
    return absDiff;
  },
}, */

  methods: {
    getChartData() {
      let self = this;
      if (!this.code) return;
      self.isLoading = true;

      let path = '/items/chart-data?code=' + this.code;

      if (this.from || self.$route.query.from) {
        path = path + '&from=' + (this.from ?? self.$route.query.from);
      }

      if (this.to || self.$route.query.to) {
        path = path + '&to=' + (this.to ?? self.$route.query.to);
      }

      if (self.dataChoice) {
        path = path + '&data_choice=' + self.dataChoice;
      }

      if (self.initialDeposit && self.initialDeposit !== null) {
        path = path + '&initial_deposit=' + self.initialDeposit;
      }

      if (self.regularDeposit && self.regularDeposit !== null) {
        path = path + '&regular_deposit=' + self.regularDeposit;
      }

      if (self.alternativeEvaluation && self.alternativeEvaluation !== null) {
        path = path + '&alternative_evaluation=' + self.alternativeEvaluation;
      }

      if (self.range && self.range !== null) {
        path = path + '&range=' + self.range;
      }

      if (self.rangeInYears && self.rangeInYears !== null) {
        path = path + '&years_range=' + self.rangeInYears;
      }



      http.get(path).then((response) => {
        self.data = response.data;
        self.series = self.data.chart_data.series;
        self.series.forEach((serie, index) => {
          if (self.series.length > 1) {
            serie.name = this.$t('chart.' + serie.name);
          }
        });
        //self.emitter.emit('statsLoaded', response.data.stats, response.data.alternative_stats);
        self.emitter.emit('statsLoaded', {stats: response.data.stats, alternative_stats: response.data.alternative_stats});
        /*let min = self.data['chart_data']['data_spec']['min_value'];
        let max = self.data['chart_data']['data_spec']['max_value'];
        let absDiff = Math.abs(max - min);
        let step = 1;

        if (absDiff <= 5) {
          step = 1;
        } else if (absDiff <= 10) {
          step = 2;
        } else if (absDiff <= 20) {
          step = 4;
        } else step = 5;

        console.log(step);*/

        let yaxes = [{
          show: true,
          tickAmount: 5,
          startAtZero: false,
          labels: {
            formatter: function (value) {
              return self.formatDataValue(value, 0);
            }
          }
        }];

        if (this.initialDeposit && this.initialDeposit !== null) {
          yaxes[0].min = this.initialDeposit;
        }

        // self.series.forEach((serie, index) => {
        //   if(index === 0) {
        //     yaxes.push({
        //       show: true,
        //       tickAmount: 5,
        //       labels: {
        //         formatter: function (value) {
        //           return self.formatDataValue(value, index);
        //         }
        //       }
        //     });
        //   }
        // });

        let xaxis = {
          categories: response.data['chart_data'].categories,
          tickAmount: 25,
          labels: {
              formatter: function (value) {
                return (response.data.chart_data['data_spec']['category_type'] && (response.data.chart_data['data_spec']['category_type'] === 'month' || response.data.chart_data['data_spec']['category_type'] === 'year')) ? value : new Date(value).toLocaleDateString();
              }
          }
        };

        if (self.minified) {
          xaxis.tickAmount = 3;
          xaxis.axisTicks = false;
          xaxis.labels = {
            formatter: function (value, timestamp, opts) {
              let date = new Date(value);

              return (response.data.chart_data['data_spec']['category_type'] && (response.data.chart_data['data_spec']['category_type'] === 'month' || response.data.chart_data['data_spec']['category_type'] === 'year')) ? value : (date.getMonth() + 1) + ". " + date.getFullYear();
            }
          };
          xaxis.labels.rotate = 0;
          xaxis.labels.offsetX = 4;
          xaxis.labels.show = true;
          yaxes[0].tickAmount = 3;

          yaxes[0].show = true;
          yaxes[0].labels = {
            show: true,
            formatter: function (value) {
              return self.formatDataValue(value, 0);
            }
          };
        }

        self.$refs.chart.updateOptions({
          colors: [window.settings.primaryColor, '#d18e6a', '#697d80'],
          yaxis: yaxes,
          xaxis: xaxis

        });

        self.isLoading = false;
      });
    },

    formatDataValue(value = 0, index) {
      if (this.data.chart_data['data_spec']['value_type'] === 'price' || (this.data.chart_data['series'][index]['data_spec'] && this.data.chart_data['series'][index]['data_spec']['value_type'] === 'amount')) {
        let currency = this.data.chart_data['data_spec']['currency'] ?? this.data.chart_data['series'][index]['data_spec']['currency'];
        if (!currency) {
          return value.toLocaleString('cs-CZ', {maximumFractionDigits: 0}) + ',-';
        }//return this.formatAmount(value, this.data.chart_data['data_spec']['currency']);
        return this.formatAmount(value, currency);
      } else {
        return this.formatPercents(value);
      }
    },

    formatAmount(value, currency) {
      let formatter = new Intl.NumberFormat('cs-CZ', {
        style: 'currency',
        currency: currency,
        maximumFractionDigits: 0,
        minimumFractionDigits: 0
      });
      return formatter.format(value);
    },

    formatPercents(value) {
      let percents = value * 100;
      return percents.toFixed(2) + ' %';
    }
  }
};
</script>
<style>
.chart-wrap {
  width: 100%;
  height: 100%;
}

.chart-wrap:not(.chart-wrap-minified) {
  min-height: 400px;
}
.chart-wrap.chart-wrap-minified {
  padding-left: 10px;
}
</style>