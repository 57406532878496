<template>
  <div class="filter">
    <div>
      <div class="row mb-3">
        <div class="col-12 col-lg-12 mt-2">
          <label class="form-label">{{ $t('chart.offerFor') }}:</label>
          <input class="form-control" v-model="client.name">
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-12 col-lg-4 mt-2">
          <label class="form-label">{{ $t('chart.offerCreatedBy') }}:</label>
          <input class="form-control" v-model="creator.name">
        </div>
        <div class="col-12 col-lg-4 mt-2">
          <label class="form-label">{{ $t('general.email') }}:</label>
          <input class="form-control" v-model="creator.email">
        </div>
        <div class="col-12 col-lg-4 mt-2">
          <label class="form-label">{{ $t('general.phoneNumber') }}:</label>
          <input class="form-control" v-model="creator.phoneNumber">
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-12 col-lg-6 mt-2">
          <label class="form-label">{{ $t('chart.categories') }}:</label>
          <div>
            <div class="row">
              <div class="col-6">
                <input
                    v-model="selectedCategory"
                    type="radio"
                    class="btn-check"
                    name="options"
                    id="option1"
                    autocomplete="off"
                    value="strategies"
                    @change="prepareAvailableProducts()"
                />
                <label class="btn btn-outline-primary d-block" for="option1">{{
                    $t('chart.strategies')
                  }}</label>
              </div>
              <div class="col-6">
                <input
                    v-model="selectedCategory"
                    type="radio"
                    class="btn-check"
                    name="options"
                    id="option2"
                    autocomplete="off"
                    value="stocks"
                    @change="prepareAvailableProducts()"
                />
                <label class="btn btn-outline-primary d-block" for="option2">{{
                    $t('chart.stocks')
                  }}</label>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 col-lg-6 mt-2">
          <label class="form-label">{{ $t('chart.product') }}:</label>
          <VueMultiselect
              v-model="selectedProduct"
              :options="filteredProducts"
              :object="false"
              label="name"
              valueProp="code"
              :close-on-select="true"
              :show-labels="false"
              :allow-empty="false"
              :placeholder="$t('chart.selectProduct')"
              @select="getItem()"
          >
          </VueMultiselect>
        </div>
      </div>
      <div class="row" v-if="item?.default_description?.value">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <div class="px-3">
                <small class="fw-bold" v-if="item?.default_description?.is_machine_translated">{{
                    $t('general.textIsMachineTranslated')
                  }}</small>
                <p class="mt-3" v-html="item?.default_description?.value"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-12 col-lg-12 mt-4">
        <fieldset>
          <div class="fieldset-input-wrapper">
            <div class="row">
              <div class="col-12 col-md-6 col-lg-6">
                <div class="form-group">
                  <label for="yearsCount" class="form-label">{{ $t('chart.yearsCount') }}</label>
                  <input type="range" class="form-range" id="yearsCount" min="1" max="30" v-model="yearsCount"
                         @change="inputRefreshData()">
                  <small class="text-muted">{{ $t('chart.selectedYearsCount') }}: {{ yearsCount }}</small>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-6">
                <div class="row">
                  <div class="col-12 col-sm-6 col-lg-6">
                    <div class="d-block d-md-none mt-2"></div>
                    <div class="form-group">
                      <label class="form-label">{{ $t('chart.initialDeposit') }}:</label>
                      <input type="number" class="form-control filter-deposit-input" v-model="initialDeposit"
                             @input="inputRefreshData">
                    </div>
                  </div>
                  <div class="col-12 col-sm-6 col-lg-6">
                    <div class="d-block d-md-none mt-2"></div>
                    <div class="form-group">
                      <label class="form-label">{{ $t('chart.regularDeposit') }}:</label>
                      <input type="number" class="form-control filter-deposit-input" v-model="regularDeposit"
                             @input="inputRefreshData">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </fieldset>
      </div>

      <div class="col-12 col-lg-12 mt-4">
        <div class="row">
          <div class="col-6">
            <fieldset>
              <div class="fieldset-input-wrapper">
                <div class="row mb-3">
                  <div class="col-12"><strong>{{ $t('chart.investmentStatistics') }}</strong></div>
                </div>
                <div class="row">

                  <div class="col-12 col-md-6 col-lg-6">
                    <div class="form-group">
                      <label class="form-label">{{ $t('chart.evaluationPerYear') }}:</label>
                      <input class="form-control filter-deposit-input text-center" v-model="formattedEvaluationPerYear"
                             disabled>
                    </div>
                  </div>
                  <div class="d-block d-md-none mt-2"></div>
                  <div class="col-12 col-md-6 col-lg-6">
                    <div class="form-group">
                      <label class="form-label">{{ $t('chart.totalEvaluation') }}:</label>
                      <input class="form-control filter-deposit-input text-center"
                             v-model="formattedTotalPercentageGain"
                             disabled>
                    </div>
                  </div>
                  <div class="d-block d-md-none mt-2"></div>


                  <div class="mt-2"></div>
                  <div class="col-12 col-md-6 col-lg-6">
                    <div class="form-group">
                      <label class="form-label">{{ $t('chart.totalDeposits') }}:</label>
                      <input class="form-control filter-deposit-input text-center" v-model="formattedTotalDeposits"
                             disabled>
                    </div>
                  </div>
                  <div class="d-block d-md-none mt-2"></div>
                  <div class="col-12 col-md-6 col-lg-6">
                    <div class="form-group">
                      <label class="form-label">{{ $t('chart.finalAmount') }}:</label>
                      <input class="form-control filter-deposit-input text-center" v-model="formattedFinalAmount"
                             disabled>
                    </div>
                  </div>


                  <div class="d-block d-md-none mt-2"></div>
                  <div class="mt-2"></div>
                  <div class="col-12">
                    <div class="form-group">
                      <label class="form-label">{{ $t('chart.totalGain') }}:</label>
                      <input class="form-control filter-deposit-input text-center" v-model="formattedTotalGain"
                             disabled>
                    </div>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>

          <div class="col-6">
            <fieldset>
              <div class="fieldset-input-wrapper">
                <div class="row mb-2" style="padding-bottom: 6px;">
                  <div class="col-12">
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" value="" id="checkedAlternativeEvaluation"
                             v-model="checkedAlternativeEvaluation" @change="updatedCheckedAlternativeEvaluation">
                      <label class="form-check-label" for="checkedAlternativeEvaluation">
                        {{ $t('chart.enterAlternativeEvaluation') }}
                      </label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div v-if="checkedAlternativeEvaluation" class="col-12 col-md-6 col-lg-6">
                    <div class="form-group">
                      <label class="form-label">{{ $t('chart.evaluationPerYear') }}:</label>

                      <input type="number" class="form-control filter-deposit-input" v-model="alternativeEvaluation"
                             @input="inputRefreshData" id="alternativeEvaluation" step=".1">

                    </div>
                  </div>
                  <div class="d-block d-md-none mt-2"></div>
                  <div v-if="checkedAlternativeEvaluation && alternativeStats" class="col-12 col-md-6 col-lg-6">
                    <div class="form-group">
                      <label class="form-label">{{ $t('chart.totalEvaluation') }}:</label>
                      <input class="form-control filter-deposit-input text-center"
                             v-model="formattedAlternativeTotalPercentageGain"
                             disabled>
                    </div>
                  </div>
                  <div class="d-block d-md-none mt-2"></div>

                </div>
                <div v-if="checkedAlternativeEvaluation && alternativeStats" class="row mt-2">


                  <div class="col-12 col-md-6 col-lg-6">
                    <div class="form-group">
                      <label class="form-label">{{ $t('chart.totalDeposits') }}:</label>
                      <input class="form-control filter-deposit-input text-center"
                             v-model="formattedAlternativeTotalDeposits"
                             disabled>
                    </div>
                  </div>
                  <div class="d-block d-md-none mt-2"></div>
                  <div class="col-12 col-md-6 col-lg-6">
                    <div class="form-group">
                      <label class="form-label">{{ $t('chart.finalAmount') }}:</label>
                      <input class="form-control filter-deposit-input text-center"
                             v-model="formattedAlternativeFinalAmount"
                             disabled>
                    </div>
                  </div>
                  <div class="mt-2"></div>
                  <div class="col-12">
                    <div class="form-group">
                      <label class="form-label">{{ $t('chart.totalGain') }}:</label>
                      <input class="form-control filter-deposit-input text-center"
                             v-model="formattedAlternativeTotalGain" disabled>
                    </div>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="card mt-4">
    <div class="card-body">
      <ChartViewComponent
          ref="chart"
          :code="selectedProduct?.code"
          :dataChoice="dataChoice"
          :initialDeposit="initialDeposit"
          :regularDeposit="regularDeposit"
          :alternativeEvaluation="alternativeEvaluation"
          :rangeInYears="yearsCount"
      ></ChartViewComponent>
      <div v-if="alternativeEvaluation" class="chart-disclaimer mt-3">* {{
          $t('chart.alternativeEvaluationText', {alternativeEvaluation: alternativeEvaluation})
        }}
      </div>
    </div>

  </div>
  <div class="chart-disclaimer mt-4">* {{ $t('chart.disclaimer') }}</div>
  <div class="d-flex justify-content-end mt-3">
    <button class="generate-offer-button" v-on:click="generatePDF();">
      <span v-if="pdfIsGenerating" class="spinner-border spinner-border-sm me-2" role="status"><span class="sr-only">Loading...</span></span>
      {{ pdfIsGenerating === false ? $t('chart.generateOffer') : $t('chart.isGenerating') }}
    </button>
  </div>


  <!-- PDF template-->
  <div class="pdf-template-wrap">
    <div
        ref="pdfTemplate" class="pdf-template">
      <div class="row justify-content-center pb-3 px-2 calculation-header">
        <div class="col-6 text-start" id="pdf-image">
          <div class="pdf-template-logo-wrap">
            <img class="pdf-template-logo" v-if="!logoSVGString" :src="logoBase64"/>
          </div>
          <div class="pdf-template-logo-wrap" v-html="logoSVGString"></div>
        </div>
        <div class="col-6 text-end" :style="'color:' + headerTextColor">
          {{ $t('chart.investmentCalculation') }}
        </div>
      </div>
      <div class="row mt-4 justify-content-center">
        <div class="col-8">
          <div class="calculation-title">
            {{ $t('chart.productCalculation') + ' ' + selectedProduct?.name }}
            <span v-if="client.name"><br/>{{ $t('chart.for') + ' ' + client.name }}</span>
          </div>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-6">
          <div class="card h-100">
            <div class="card-body px-4">
              <div class="row py-1 border-bottom">
                <div class="col-5">{{ $t('item_detail.name') }}:</div>
                <div class="col-7 fw-bold">{{ selectedProduct?.name }}</div>
              </div>
              <div class="row py-1 border-bottom">
                <div class="col-5">{{ $t('item_detail.type') }}:</div>
                <div class="col-7">{{
                    selectedProduct?.type ? (selectedProduct?.type === 'STRATEGY' ? $t('item_detail.strategyOf', {brand: branding.company_name}) : $t('item_detail.stocks')) : '---'
                  }}
                </div>
              </div>
              <div class="row py-1">
                <div class="col-5">{{ $t('item_detail.currency') }}:</div>
                <div class="col-7">{{ selectedProduct?.currency ?? '---' }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="card h-100">
            <div class="card-body px-4">
              <div class="row py-1 border-bottom">
                <div class="col-5">{{ $t('chart.broker') }}:</div>
                <div class="col-7 fw-bold">{{ creator.name ?? '---' }}</div>
              </div>
              <div class="row py-1 border-bottom">
                <div class="col-5">{{ $t('general.phone') }}:</div>
                <div class="col-7">{{ creator.phoneNumber ?? '---' }}</div>
              </div>
              <div class="row py-1 border-bottom">
                <div class="col-5">{{ $t('general.email') }}:</div>
                <div class="col-7">{{ creator.email ?? '---' }}</div>
              </div>
              <div class="row py-1">
                <div class="col-5">{{ $t('chart.generatingDate') }}:</div>
                <div class="col-7">{{ new Date(Date.now()).toLocaleString() }}</div>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div class="row mt-4" v-if="item?.default_description?.value">
        <div class="col-12">
          <div class="card card--about-investment">
            <div class="card-body">
              <div class="message ps-0">
                <p v-if="item?.default_description?.is_machine_translated">{{
                    $t('general.textIsMachineTranslated')
                  }}</p>
                <p class="px-3 mt-3" v-html="item?.default_description?.value"></p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="pt-2" style="page-break-inside: avoid !important;">
        <div class="row mt-4">
          <div class="col-6">
            <div class="card mb-3" style="height: 100%;">
              <div class="card-body px-4">
                <div class="row py-1 border-bottom">
                  <div class="col-5">{{ $t('chart.investmentDuration') }}:</div>
                  <div class="col-7">{{ yearsCount + " (" + $t('chart.inYears') + ")" }}</div>
                </div>
                <div class="row py-1 border-bottom">
                  <div class="col-5">{{ $t('chart.initialDeposit') }}:</div>
                  <div class="col-7">{{ formattedInitialDeposit }}</div>
                </div>
                <div class="row py-1 border-bottom">
                  <div class="col-5">{{ $t('chart.regularDeposit') }}:</div>
                  <div class="col-7">{{ formattedRegularDeposit }}</div>
                </div>
                <div class="row py-1 border-bottom">
                  <div class="col-5">{{ $t('chart.totalDeposits') }}:</div>
                  <div class="col-7">{{ formattedTotalDeposits }}</div>
                </div>
              </div>
            </div>
          </div>


          <div class="col-6">
            <div class="card" style="height: 100%;">
              <div class="card-body px-0 py-0 pb-2">
                <div class="chart-card-body">
                  <ChartViewComponent
                      ref="chart2"
                      :code="selectedProduct?.code"
                      :dataChoice="dataChoice"
                      :initialDeposit="initialDeposit"
                      :regularDeposit="regularDeposit"
                      :alternativeEvaluation="alternativeEvaluation"
                      :minified="true"
                      :brandingPrimaryColor="branding.primary_color"
                      :rangeInYears="yearsCount"
                  ></ChartViewComponent>
                </div>
                <div v-if="alternativeEvaluation" class="chart-disclaimer mt-3 px-2">* {{
                    $t('chart.alternativeEvaluationText', {alternativeEvaluation: alternativeEvaluation})
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="pt-2" style="page-break-inside: avoid !important;">
        <div class="row mt-4">
          <div class="col-6">
            <div class="card" style="height: 100%;">
              <div class="card-body">
                <div class="row mb-2">
                  <div class="col-12"><strong>{{ $t('chart.investmentStatistics') }}</strong></div>
                </div>
                <div class="row py-1 border-bottom">
                  <div class="col-5">{{ $t('chart.evaluationPerYear') }}:</div>
                  <div class="col-7">{{ formattedEvaluationPerYear }}</div>
                </div>
                <div class="row py-1 border-bottom">
                  <div class="col-5">{{ $t('chart.percentageGain') }}:</div>
                  <div class="col-7">{{ formattedTotalPercentageGain }}</div>
                </div>
                <div class="row py-1 border-bottom">
                  <div class="col-5">{{ $t('chart.finalAmount') }}:</div>
                  <div class="col-7">{{ formattedFinalAmount }}</div>
                </div>
                <div class="row py-1 align-items-center">
                  <div class="col-5">{{ $t('chart.totalGain') }}:</div>
                  <div class="col-7 highlight-main-amount">{{ formattedTotalGain }}</div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="alternativeStats" class="col-6">
            <div class="card" style="height: 100%;">
              <div class="card-body" style="padding-bottom: 0px !important;">
                <div class="row mb-2">
                  <div class="col-12"><strong>{{ $t('chart.investmentStatisticsAlternative') }}</strong></div>
                </div>
                <div class="row py-1 border-bottom">
                  <div class="col-5">{{ $t('chart.evaluationPerYear') }}:</div>
                  <div class="col-7">{{ formattedAlternativeEvaluationPerYear }}</div>
                </div>
                <div class="row py-1 border-bottom">
                  <div class="col-5">{{ $t('chart.percentageGain') }}:</div>
                  <div class="col-7">{{ formattedAlternativeTotalPercentageGain }}</div>
                </div>
                <div class="row py-1 border-bottom">
                  <div class="col-5">{{ $t('chart.finalAmount') }}:</div>
                  <div class="col-7">{{ formattedAlternativeFinalAmount }}</div>
                </div>
                <div class="row py-1 align-items-center">
                  <div class="col-5">{{ $t('chart.totalGain') }}:</div>
                  <div class="col-7 highlight-main-amount">{{ formattedAlternativeTotalGain }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="pt-2" style="page-break-inside: avoid !important;">
        <div class="row">
          <div class="col-12">
            <div class="chart-disclaimer mt-3">* {{ $t('chart.disclaimer') }}</div>
          </div>
        </div>
      </div>

      <div v-if="item && item.composition && item.composition.length">

        <div class="row mt-5 justify-content-center">
          <div class="col-8">
            <div class="calculation-title">
              {{ $t('chart.productCalculationComposition') }}
            </div>
          </div>
        </div>

        <div v-for="compositionItem in item.composition" :key="compositionItem.id">

          <div class="row mt-5">
            <div class="col-12">
              <div class="calculation-subtitle">
                {{ compositionItem.name }}
                <div v-if="compositionItem.isin" class="calculation-subtitle-sub">
                  ISIN: {{ compositionItem.isin }}
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-4" v-if="compositionItem?.default_description?.value"
               style="page-break-inside: avoid !important;">
            <div class="col-12">
              <div class="card card--about-investment">
                <div class="card-body">
                  <div class="message ps-0">
                    <p v-if="compositionItem?.default_description?.is_machine_translated">{{
                        $t('general.textIsMachineTranslated')
                      }}</p>
                    <p class="px-3 mt-3" v-html="compositionItem?.default_description?.value"></p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row" style="page-break-inside: avoid !important;">
            <div class="col-12">
              <div class="card mt-3">
                <div class="card-header fw-bold">{{ $t('chart.productCalculationPerformance') }}</div>
                <div class="card-body px-4 py-1">
                  <div class="row border-bottom">
                    <div class="col p-2" v-for="performanceValue in performanceValues" :key="performanceValue" style="font-size: .8em;">
                      {{ $t('chart.productCalculation' + performanceValue) }}
                    </div>
                  </div>
                  <div class="row">
                    <div class="col p-2" v-for="performanceValue in performanceValues" :key="performanceValue">
                      <div
                          v-if="compositionItem.performance_data && compositionItem.performance_data[performanceValue]"
                          style="font-size: .8em;"
                          >
                        <strong>{{ compositionItem.performance_data[performanceValue] + ' %' }}</strong>

                        <span
                            v-if="performanceValue.startsWith('Return') && compositionItem.performance_data[performanceValue] > 0"
                            class="ms-2"><font-awesome-icon
                            v-if="compositionItem.performance_data[performanceValue] > 0" icon="arrow-up"
                            class="text-success"></font-awesome-icon></span>
                        <span
                            v-if="performanceValue.startsWith('Return') && compositionItem.performance_data[performanceValue] < 0"
                            class="ms-2"><font-awesome-icon
                            v-if="compositionItem.performance_data[performanceValue] < 0" icon="arrow-down"
                            class="text-danger"></font-awesome-icon></span>
                      </div>
                      <div v-else>
                        --
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="chart-disclaimer mt-3 text-end">* {{ $t('item_detail.compositionDisclaimer') }}</div>
          </div>
        </div>
      </div>


    </div>
  </div>
</template>

<script>
import http from '@/http';
import html2pdf from 'html2pdf.js';

export default {
  props: ['itemFromDetail', 'embedded'],
  data() {
    return {
      item: {...this.itemFromDetail},
      selectedFrom: null,
      selectedTo: null,
      from: new Date().getFullYear() + '-01-01',
      to: new Date().toISOString().split('T')[0],
      product: {...this.itemFromDetail},
      products: [],
      allProducts: [],
      filteredProducts: [],
      selectedProduct: this.itemFromDetail ? {...this.itemFromDetail} : null,
      isLoading: false,
      selectedYear: 'YTD',
      years: [],
      customRange: false,
      dataChoice: 'percentage',
      availableDataChoices: [],
      options: [],
      selectedCategory: 'strategies',
      initialDeposit: 0,
      regularDeposit: 0,
      stats: [],
      alternativeStats: [],
      inputRefreshDataTimeout: null,
      pdfIsGenerating: false,
      checkedAlternativeEvaluation: false,
      alternativeEvaluation: null,
      monthCount: 50,
      yearsCount: 5,
      logoBase64: null,
      logoSVGString: null,
      client: {
        name: null,
      },
      headerTextColor: '#000',
      creator: {
        name: null,
        email: null,
        phoneNumber: null,
      },
      performanceValues: [
        '1y_Volatility',
        '3y_ExpReturn',
        '3y_SharpRatio',
        '3y_Volatility',
        'Returns_1Y',
        'Returns_3Y',
        'Returns_5Y',
        'Returns_10Y',
        'Returns_YTD'
      ],
      branding: {
        'company_name': 'IN Equity Slovakia, o.c.p., a.s.',
        'primary_color': '#004148',
        //'primary_color': '#ff0000',
        'logo_url': new URL('@/images/logo-2.png', import.meta.url).href,
        'name': 'Ing. Tomáš V. Michálek',
        'phone': '+420 558 274 245',
        'email': 'info@in-equity.cz',
        'customer': {
          'name': 'Milan Mydlarčík'
        }
      },
    };
  },

  mounted() {
    //document.documentElement.setAttribute('data-bs-theme', this.settings.tenantCode);
    if (!this.itemFromDetail) {
      this.getAllProducts();
      //this.prepareAvailableProducts();
    } else {
      this.getAllProducts();
      this.selectedCategory =
          this.itemFromDetail.type === 'STRATEGY' ? 'strategies' : 'stocks';
    }
    this.initialDeposit = 100000;
    this.regularDeposit = 2000;

    this.emitter.on('statsLoaded', (stats) => {
      this.stats = stats.stats;
      this.alternativeStats = stats.alternative_stats ? stats.alternative_stats : null;
    });
    // this.prepareAvailableProducts();
  },

  methods: {
    prepareAvailableProducts() {
      let self = this;
      if (this.selectedCategory === 'strategies') {
        self.fillAvailableDataChoices();
        self.filteredProducts = this.products.filter((product) => product.type === 'STRATEGY');
        self.selectedProduct = self.filteredProducts[0];
        //self.products this.products.filter((product) => product.type === 'STRATEGY');
        self.getItem();
        self.updateRange();
      } else {
        self.fillAvailableDataChoices();
        self.filteredProducts = this.products.filter((product) => product.type !== 'STRATEGY');
        self.selectedProduct = self.filteredProducts[0];
        self.getItem();
        self.updateRange();
      }
    },

    countHeaderTextColor() {
      let color = window.settings.primaryInterfaceColor;
      let hexcolor = color.replace('#', '');

      // Převod hexadecimální hodnoty na decimální
      var r = parseInt(hexcolor.substring(0, 2), 16);
      var g = parseInt(hexcolor.substring(2, 4), 16);
      var b = parseInt(hexcolor.substring(4, 6), 16);

      // Výpočet YIQ
      var yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
      this.headerTextColor = (yiq >= 128) ? '#000' : '#fff';

    },

    svg2png(svgString, width, height, format, callback) {
      // set default for format parameter
      format = format ? format : 'png';
      // SVG data URL from SVG string
      // var svgData = 'data:image/svg+xml;base64,' + btoa(unescape(encodeURIComponent(svgString)));
      var svgData = svgString;
      // create canvas in memory(not in DOM)
      var canvas = document.createElement('canvas');
      // get canvas context for drawing on canvas
      var context = canvas.getContext('2d');
      // set canvas size
      canvas.width = width;
      canvas.height = height;
      // create image in memory(not in DOM)
      var image = new Image();
      // later when image loads run this
      image.onload = function () { // async (happens later)
        // clear canvas
        context.clearRect(0, 0, width, height);
        // draw image with SVG data to canvas
        context.drawImage(image, 0, 0, width, height);
        // snapshot canvas as png
        var pngData = canvas.toDataURL('image/' + format);
        // pass png data URL to callback
        callback(pngData);
      }; // end async
      // start loading SVG data into in memory image
      image.src = svgData;
    },

    generatePDF() {
      this.countHeaderTextColor();
      this.logoBase64 = window.settings?.logoBase64;
      if (this.logoBase64.startsWith('data:image/svg')) {
        this.logoSVGString = atob(this.logoBase64.split("base64,")[1]);
      }

      let self = this;
      this.pdfIsGenerating = true;


      let clientString = self.client.name ? ' (' + self.$t('chart.client') + ' - ' + self.client.name + ')' : '';
      const options = {
        margin: [5, 10],
        html2canvas: {
          scale: 4,
        },
        filename: self.$t('chart.productInvestmentDevelopment') + ' ' + self.selectedProduct.name + clientString + '.pdf',
        pagebreak: {mode: ['css']},
      };

      const doc = self.$refs.pdfTemplate;
      // html2pdf(doc, options)
      html2pdf().set(options).from(doc).save().then(function () {
        self.pdfIsGenerating = false;
      });
    },

    selectedDataChoice() {
      this.refreshData();
    },

    categorySelected() {
      if (!this.itemFromDetail) {
        this.selectedProduct = this.item;
        this.refreshData();
      } else {
        this.selectedProduct = this.filteredProducts[0];
        this.refreshData();
      }
      this.getOptions();
    },

    fillAvailableDataChoices() {
      if (this.selectedCategory === 'strategies') {
        this.availableDataChoices = ['percentage'];
      } else {
        this.availableDataChoices = ['price', 'percentage'];
      }
      this.dataChoice = this.availableDataChoices[0];

      this.dataChoice = null;
    },

    getAllProducts() {
      let self = this;
      let url = '/items/get-all';
      if (self.$route.query.locale) {
        url = url + '?locale=' + self.$route.query.locale;
      } else if (this.$i18n.locale) {
        url = url + '?locale=' + this.$i18n.locale;
      } else {
        url = url + '?locale=cs';
      }
      if (window.tenant_code) {
        url = url + '&tenant_code=' + window.tenant_code;
      }

      http.get(url).then((response) => {
        self.products = response.data.items;
        //self.allProducts = response.data.items;
        /*self.selectedProduct = self.selectedProduct ?? response.data.items[0];
        self.getOptions();
        self.getItem();*/
        this.prepareAvailableProducts();
      });
    },

    getOptions() {
      let self = this;
      //let checkObject = null;
      self.options = [];
      if (self.filteredProducts) {
        self.selectedProduct = self.filteredProducts[0];
        /*for (let key in self.filteredProducts) {
          checkObject = self.filteredProducts[key];
          if (checkObject.public) {
            self.options.push(checkObject);
          }
        }*/
        self.options = self.filteredProducts;
      }
    },

    getItem() {
      let self = this;
      let localeString = "";
      if (self.$route.query.locale) {
        localeString = '?locale=' + self.$route.query.locale;
      } else if (this.$i18n.locale) {
        localeString = '?locale=' + this.$i18n.locale;
      } else {
        localeString = '?locale=cs';
      }
      http.get('/items/' + self.selectedProduct.code + localeString).then((response) => {
        self.item = response.data.item;
        self.item.default_description = response.data.default_description;
        self.years = response.data.item.years_of_duration.reverse();
        self.years[0] = 'YTD';
        self.years[self.years.length - 1] =
            self.years[self.years.length - 1] +
            ' (' +
            self.$t('chart.dateOfFoundation') +
            ':' +
            new Date(response.data.item.foundation_date).toLocaleDateString() +
            ')';
        self.years[self.years.length] = this.$t('chart.customPeriod');
        self.selectedFrom = new Date(self.item.foundation_date).toISOString().split('T')[0];
        //self.selectedTo = new Date(Date.now()).toISOString().split('T')[0];
        let preparedDateTo = new Date(self.item.foundation_date).setFullYear(new Date(self.item.foundation_date).getFullYear() + 5);
        self.selectedTo = new Date(preparedDateTo).toISOString().split('T')[0];

        self.updateRange();
        self.fillAvailableDataChoices();
      });
    },

    updatedCheckedAlternativeEvaluation() {
      let self = this;
      if (!self.checkedAlternativeEvaluation) {
        self.alternativeEvaluation = null;
        setTimeout(() => {
          self.refreshData();
        }, 500);
      }
    },

    updateRange() {
      let self = this;
      self.from = self.selectedFrom;
      self.to = self.selectedTo;

      self.refreshData();
    },

    refreshData() {
      this.$refs.chart.getChartData();
      this.$refs.chart2.getChartData();
    },

    inputRefreshData() {
      let self = this;

      if (this.initialDeposit === null)
        return;

      if (this.inputRefreshDataTimeout)
        clearTimeout(this.inputRefreshDataTimeout);

      this.inputRefreshDataTimeout = setTimeout(() => {
        self.refreshData();
      }, 500);
    },

    formatNumber(value) {
      return value ? value.toLocaleString() : null;
    },

    formatAmount(value) {
      let currency = this.selectedProduct?.currency ?? 'CZK';

      if (currency) {
        let formatter = new Intl.NumberFormat('cs-CZ', {
          style: 'currency',
          currency: currency,
          maximumFractionDigits: 0,
          minimumFractionDigits: 0
        });
        return formatter.format(value);
      } else {
        return value.toLocaleString() + ',-';
      }
    },

    formatPercents(value) {
      let percents = value * 100;
      return percents.toFixed(2) + ' %';
    },

    /*getContrastColor(hexcolor) {
      // Odebrání "#" pokud je přítomno
      // Vrácení kontrastní barvy

      hexcolor = hexcolor.replace('#', '');

      // Převod hexadecimální hodnoty na decimální
      var r = parseInt(hexcolor.substring(0, 2), 16);
      var g = parseInt(hexcolor.substring(2, 4), 16);
      var b = parseInt(hexcolor.substring(4, 6), 16);

      // Výpočet YIQ
      var yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
      console.log(hexcolor);
      console.log(this.settings);
      console.log(window.settings);
      console.log((window.settings.primaryColor));
      console.log(this.themeSettings);
      console.log((yiq >= 128) ? 'black' : 'white');
      return (yiq >= 128) ? '' : 'text-white';
    }*/
  },

  computed: {
    /*filteredProducts() {
      let self = this;
      if (this.selectedCategory === 'strategies') {
        this.fillAvailableDataChoices();
        self.products = this.allProducts;
        return this.products.filter((product) => product.type === 'STRATEGY');
      } else {
        this.fillAvailableDataChoices();
        self.products = this.allProducts;
        return this.products.filter((product) => product.type !== 'STRATEGY');
      }
    },*/

    formattedTotalDeposits() {
      return this.formatAmount(this.stats?.total_deposits ?? 0);
    },

    formattedFinalAmount() {
      return this.formatAmount(this.stats?.final_amount ?? 0);
    },

    formattedEvaluationPerYear() {
      return this.formatPercents(this.stats?.percentage_gain_per_year ?? 0);
    },

    formattedInitialDeposit() {
      return this.formatAmount(this.initialDeposit);
    },

    formattedRegularDeposit() {
      return this.formatAmount(this.regularDeposit);
    },

    formattedTotalPercentageGain() {
      return this.formatPercents(this.stats?.total_percentage_gain ?? 0);
    },

    formattedTotalGain() {
      return this.formatAmount(this.stats?.total_gain ?? 0);
    },

    formattedAlternativeTotalDeposits() {
      return this.formatAmount(this.alternativeStats?.total_deposits ?? 0);
    },

    formattedAlternativeFinalAmount() {
      return this.formatAmount(this.alternativeStats?.final_amount ?? 0);
    },

    formattedAlternativeEvaluationPerYear() {
      return this.formatPercents(this.alternativeStats?.percentage_gain_per_year ?? 0);
    },

    formattedAlternativeTotalPercentageGain() {
      return this.formatPercents(this.alternativeStats?.total_percentage_gain ?? 0);
    },

    formattedAlternativeTotalGain() {
      return this.formatAmount(this.alternativeStats?.total_gain ?? 0);
    }
  },

  watch: {
    /*selectedFrom: function () {
      this.from = this.selectedFrom;
      //this.$refs.chart.getChartData(this.item.code, this.from, this.to);
      let self = this;
      setTimeout(function () {
        self.updateRange();
      }, 200);
    },

    selectedTo: function () {
      this.to = this.selectedTo;
      //this.$refs.chart.getChartData(this.item.code, this.from, this.to);
      let self = this;
      setTimeout(function () {
        self.updateRange();
      }, 200);
    },*/
  }
};
</script>

<style>
.pdf-template-wrap {
  height: 0;
  overflow: hidden;
}

.calculation-header {
  display: flex;
  align-items: center;
  padding-top: 10px;
  background: var(--t-primary-interface-color);
}

.calculation-title {
  text-align: center;
  font-size: 1.65em;
  font-weight: 700;
  color: var(--t-primary-color);
}

.calculation-subtitle {
  font-size: 1.25em;
  font-weight: 700;
  color: var(--t-primary-color);
}

.calculation-subtitle-sub {
  font-size: .75em;
  font-weight: 400;
}


.pdf-template {
  font-size: .8em;
}

.pdf-template-logo-wrap {
  text-align: left;
}

.pdf-template-logo-wrap svg,
.pdf-template-logo {
  max-width: 200px;
  width: auto;
  height: 40px;
}

.pdf-template-logo {
  max-width: 200px;
  max-height: 40px;
}

.pdf-template .chart-disclaimer {
  font-size: .7em;
}

.pdf-template .filter-deposit-input {
  height: 36px;
  font-size: 1em;
}

.chart-card-body {
  width: 328px;
  height: 268px;
}

.card.card--about-investment .avatar {
  height: 40px;
  width: 40px;
}

.card.card--about-investment .card-title {
  font-size: 1.2em;
  font-weight: 700;
}

.card.card--about-investment .card-subtitle {
  font-size: 1em;
}

.card.card--about-investment .message .SmallHeading {
  font-weight: 700;
  font-size: 1.1em;
}

.highlight-main-amount {
  font-size: 1.5em;
  color: var(--t-primary-color);
  font-weight: 700;
}

.text-branding-primary {
  color: v-bind('settings.primaryColor');
}

.border-bottom-branding-primary {
  border-bottom: 2px var(--t-primary-color) solid;
}


</style>